import React from 'react'
import { Link } from 'react-router-dom'


const AttendanceBox = () => {
    return (
        <div className='box col-sm-6'>
            <div className='attendancebox'>

                <h3 className='text-center'>MCA SEM1 SECA</h3>
                <hr />
                <div className='container-fluid row'>
                    <div className='col-sm-6'><p>Date Created - 24/11/2022</p></div>
                    <div className='col-sm-6'><p>Student Present - 54</p></div>
                    <div className='col-sm-12 text-center'>
                        <img width="50%" src='https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=windikate.com'></img>
                    </div>
                    <div className='col-sm-12 text-center'>
                        <br></br>
                        <Link to="" className='btn btn-dark'>
                            View Student List
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AttendanceBox