import React from 'react'
import AttendaceList from '../component/AttendaceList'
import Navbar from '../component/Navbar'

const Attendance = () => {
    return (
        <div>
            <Navbar active={"attendance"}></Navbar>
            <div className='container-fluid '>

            </div>
            <AttendaceList></AttendaceList>
        </div>
    )
}

export default Attendance