import React from 'react'
import Navbar from '../component/Navbar'

const Home = () => {
    return (
        <div>
        <Navbar active={"home"}></Navbar>
            Welcome to attendance Marking software
        </div>
    )
}

export default Home