import React, { useState } from 'react'
import { useGeolocated } from "react-geolocated";
// https://herbocarenaturals.com/img/product/room-freshner/cover.JPG
// https://herbocarenaturals.com/img/product/room-freshner/stage.jpg

const GetLoc = () => {
    

    const [img, setImg] = useState("https://herbocarenaturals.com/img/product/room-freshner/cover.JPG")
  return (
    <div className='text-center'>
        <img src={img} width="20%"></img>

    <button className='btn btn-primary' onClick={() =>{
      img=="https://herbocarenaturals.com/img/product/room-freshner/stage.jpg"?setImg("https://herbocarenaturals.com/img/product/room-freshner/cover.JPG"):setImg("https://herbocarenaturals.com/img/product/room-freshner/stage.jpg")}}>increase</button>
    <button className='btn btn-primary' onClick={() =>{}}>decrease</button>
    </div>
  )
}
export default GetLoc