import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGeolocated } from "react-geolocated";
import axios from 'axios';
// windikate.com/attendance/api/markattendance.php?uid=Windikate@blockchain314&roll=56&lat=45.6565&lang=56.6656&ip=56.66.546.222

const Markattendance = () => {
    let {classid} = useParams();
    const [roll, setRoll] = useState("")
    const [ip, setIP] = useState('');
    const [coord, setcoord] = useState({})

    //creating function to load ip address from the API
    const getIP = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res);
      setIP(res.data.IPv4);
      
    }
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });
      getIP();
 
    const senreq = async (lat,lang,classid,roll) => { 
        console.log(lat);
        console.log(lang);
        console.log(classid);
        console.log(roll);
        console.log(ip);
        var api = `https://windikate.com/attendance/api/markattendance.php?uid=Windikate@blockchain314&roll=${roll}&lat=${coords.latitude}&lang=${coords.longitude}&ip=${ip}`
        const res = await axios.get(api)    
        window.location.href = "https://windikate.com";

     }
  return (
    <div className='text-center'>
    <br></br>
    <h1>Class Code - {classid}</h1>
    <br></br>
    <br></br>
        <div className='container row margin-0-auto'>
            <div className='col-sm-6'>
                <input type="text" className='form-control' value={roll} onChange={(e)=>{setRoll(e.target.value)}}></input>
            </div>
            <div className='col-sm-6'>
            <br></br>
                <button className='btn btn-primary' onClick={()=>{senreq(coords.latitude,coords.longitude,classid,roll)}}> Mark Now </button>
            </div>
        </div>
    </div>
  )
}

export default Markattendance