import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Attendance from './pages/Attendance';
import GetLoc from './pages/GetLoc';
import Markattendance from './pages/Markattendance';

function App() {
  return (
    <Router>
      <div className="App">
      {/* <h1>hello</h1> */}
        <Routes>
          {/* <Route path="*" element={<Pag.000000000000000000000000enotfound></Pag.0enotfound>} /> */}
          <Route path="/" exact element={<Home></Home>} />
          <Route path="/attendance" exact element={<Attendance></Attendance>} />
          <Route path="/getloc" exact element={<GetLoc></GetLoc>} />
          <Route path="/markattendance/:classid" exact element={<Markattendance></Markattendance>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
