import React from 'react'
import AttendanceBox from './AttendanceBox'

const AttendaceList = () => {
    return (
        <div>
            <div className='container row margin-0-auto'>
                <AttendanceBox></AttendanceBox>
                <AttendanceBox></AttendanceBox>
            </div>
        </div>
    )
}

export default AttendaceList